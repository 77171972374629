import React, { Component } from 'react';

import AcidApp from "./AcidApp";
import CatApp from "./CatApp";

type SubAppProps = {
    subAppKey: string;
    returnFunc: () => void;
}

export default class SubApp extends Component<SubAppProps, any> {

    components: ({ [key: string]: any }) = {
        "AcidApp": AcidApp,
        "CatApp": CatApp
    }

    render() {
        const AppTagName = this.components[this.props.subAppKey];
        return <div className="sub-app-container">
            <div className="sub-app-navigate-back"
                onClick={ev => {
                    this.props.returnFunc();
                }}
            >
                <div className="arrow-left"/>
                <div className="navigate-text">Back to App selection</div>
            </div>
            <div className="app-main">
                <div className="sub-app">
                    <AppTagName />
                </div>
            </div>
        </div>;
    }
}