import React from "react";
import _GenericSubApp from "./_GenericSubApp";

import Container from "../components/Container";

type AcidAppProps = {
}

type AcidAppState = {
}

export default class AcidApp extends _GenericSubApp<AcidAppProps, AcidAppState> {
    render() {
        return <div className="acid-app">
            <Container title="psychedelic stuff" 
                description="click and get eye-candy"
            />
        </div>;
    }
}