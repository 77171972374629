import { Component } from "react";

type ComponentBaseProps = {
}
type ComponentBaseState = {
}

export default class ComponentBase<P extends ComponentBaseProps, S extends ComponentBaseState> extends Component<P, S> {
    public className(...classNames: (string | undefined | null | false)[]): string {
        return classNames.filter(s => !!s).join(" ");
    }
}