import React from 'react';
import './style/main.css';

import ComponentBase from "./components/_ComponentBase";
import Container from "./components/Container";
import SubApp from "./subApps/_SubAppSelection";

type AppState = {
  activeSubAppKey: string | null;
}

export default class App extends ComponentBase<any, AppState> {
  componentWillMount() {
    this.setState({ activeSubAppKey: null });
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if (this.state.activeSubAppKey == null) {
      let node = document.createElement("DIV");
      node.setAttribute("background", "");

      const base = document.querySelector(`.app-container`) as HTMLElement;
      if (base) {
        const bgColor = window.getComputedStyle(node).backgroundColor;

        document.body.style.backgroundColor = bgColor;
        base.style.backgroundColor = bgColor;
      }
    }
  }

  render() {
    if (this.state.activeSubAppKey == null) {
      return <div className="app-container">
        <div className="app-main">
          <Container title="Cats!"
            description="an app about cats"
            onClick={ev => this.onSubAppSelect("CatApp")}
          />
          <Container title="Psychedelic stuff"
            description="visual effects with css"
            onClick={ev => this.onSubAppSelect("AcidApp")}
          />
        </div>
      </div>;
    } else {
      return <div className="app-container">
        <SubApp subAppKey={this.state.activeSubAppKey}
          returnFunc={this.returnToMainApp.bind(this)}
        />
      </div>
    }
  }

  private onSubAppSelect(key: string | null) {
    this.setState({ activeSubAppKey: key });
  }

  private returnToMainApp() {
    this.onSubAppSelect(null);
  }
}