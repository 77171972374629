
export type CatImage = {
  id: string;
  url: string;
  width: number;
  height: number;
}

export default class CatApi {
    public static randomPicture(callback: (response: CatImage) => void): void {
        this.ajax_get('https://api.thecatapi.com/v1/images/search?size=full', data => {
            callback(data[0]);
          });
    }

    private static ajax_get(url: string, callback: (data: any) => void): void {
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.onreadystatechange = function() {
          if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            try {
              var data = JSON.parse(xmlhttp.responseText);
            } catch (err) {
              console.log(err.message + " in " + xmlhttp.responseText);
              return;
            }
            callback(data);
          }
        };
      
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
      }
}