import React from "react";

import ComponentBase from "../components/_ComponentBase";

type _GenericSubAppProps = {
}

type _GenericSubAppState = {
}

export default abstract class _GebericSubApp<P extends _GenericSubAppProps, S extends _GenericSubAppState> extends ComponentBase<P, S> {
    componentDidMount() {
        let node = document.createElement("DIV");
        node.setAttribute("background", "");

        const base = document.querySelector(`.sub-app`)?.firstChild;
        const appBase = document.querySelector(`.app-container`) as HTMLElement;
      if (base && appBase) {
            node = base.appendChild(node);
            const bgColor = window.getComputedStyle(node).backgroundColor;
            
            document.body.style.backgroundColor = bgColor;
            appBase.style.backgroundColor = bgColor;
        }
    }
}