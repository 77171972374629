import React, { CSSProperties } from "react";

import ComponentBase from "./_ComponentBase";

type ContainerProps = {
    title?: string;
    description?: string;
    innerElement?: JSX.Element;
    onClick?: (ev: React.MouseEvent) => void;
    customClass?: string;
    customStyle?: CSSProperties;
}

type ContainerState = {
}

export default class Container extends ComponentBase<ContainerProps, ContainerState> {
    render() {
        return <div className={this.className("container", this.props.onClick && "container-clickable", this.props.customClass)}
            onClick={ev => this.props.onClick && this.props.onClick(ev)}
            style={this.props.customStyle}
        >   
            {
                this.props.title ? 
                <div className="container-title">{this.props.title}</div> :
                null
            }
            {
                this.props.title && (this.props.description || this.props.innerElement) ?
                <div className="seperator-horizontal" /> :
                null
            }
            {
                this.props.description ?
                <div className="container-description">{this.props.description}</div> :
                null
            }
            {
                this.props.innerElement ?
                this.props.innerElement :
                null
            }
        </div>;
    }
}
