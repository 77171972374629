import React from "react";
import _GenericSubApp from "./_GenericSubApp";

import Container from "../components/Container";
import CatApi, { CatImage } from "../util/CatApi";

enum ImageState { Display, RemovePending, Remove };

interface ImageObject extends CatImage {
    image: HTMLImageElement;
}

type CatAppProps = {
}

type CatAppState = {
    catImages: ImageObject[];

    imageToSwipe?: string | null;
    imageToRemove?: string | null;
}

export default class CatApp extends _GenericSubApp<CatAppProps, CatAppState> {
    componentWillMount() {
        this.setState({ catImages: [] });
    }

    componentDidUpdate() {
    }

    render() {
        return <div className="cat-app">
            <Container title="cat stuff"
                description="click and get cats"
                onClick={ev => {
                    CatApi.randomPicture(res => {
                        const imageObj = res as ImageObject;
                        imageObj.image = new Image();
                        imageObj.image.src = res.url;
                        this.setState({ catImages: this.state.catImages.concat(imageObj) });

                        const handle = setInterval(() => {
                            if (imageObj.image.complete) {
                                clearInterval(handle);
                                document.querySelector(`[data-id="${res.id}"] > .cover`)?.classList.add("open");
                            }
                        }, 50);
                    });
                }}
            />
            {
                this.state.catImages.map(catImg => {
                    const defaultContainer = document.querySelector(".container");
                    if (!defaultContainer) return null;

                    const width = defaultContainer.clientWidth - parseFloat(window.getComputedStyle(defaultContainer).paddingLeft) * 2;
                    const height = catImg.height * ((width || 1) / catImg.width);

                    return <Container customClass={this.className("cat-container", "container-no-hover-scale", this.state.imageToSwipe == catImg.id && "image-removed")}
                        innerElement={<div className="image"
                            data-id={catImg.id}
                            style={{
                                backgroundImage: `url("${catImg.url}")`,
                                height
                            }}
                        >
                            <div className="cover" />
                        </div>}
                        customStyle={{ height }}
                        onClick={ev => {
                            this.swipeAwayCatImage(catImg.id);
                        }}
                    />
                })
            }
        </div>;
    }

    private swipeAwayCatImage(id: string): void {
        this.setState({
            catImages: this.state.catImages.filter(img => img.id != this.state.imageToRemove),
            imageToRemove: this.state.imageToSwipe || null,
            imageToSwipe: id
        });
    }
}